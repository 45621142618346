import React from "react";
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LoginIcon from '@mui/icons-material/Login';
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { adminUsers } from "../../admin-config";
import { loginRequest } from '../../authConfig';


const Sidebar = () => {
  let navigate = useNavigate();
  const onClickListHandler = () => {
    navigate("/list");
  };

  const onClickRegisterUserHandler = () => {
    navigate("/register");
  };

  const onClickCheckInUserHandler = () => {
    navigate("/checkin");
  };

  const onClickDashboardHandler = () => {
    navigate("/");
  };

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  return (
    <div className="sidebar">
      <div className="top">
        <span className="logo"> DC4 Stats </span>
      </div>
      <hr />
      <AuthenticatedTemplate>
        <div className="center">
          <ul>
            <p className="title">MAIN</p>
            <p className="title">USERS</p>
            <li onClick={onClickRegisterUserHandler}>
              <GroupAddOutlinedIcon className="icon" />
              <span>Register User</span>
            </li>
            {adminUsers.includes(activeAccount?.username) &&
              <li onClick={onClickListHandler}>
                <RecentActorsOutlinedIcon className="icon" />
                <span>Registered Users</span>
              </li>
            }
            <p className="title">OTHER</p>
            <li onClick={handleLogoutRedirect}>
              <LogoutOutlinedIcon className="icon" />
              <span>Logout</span>
            </li>
          </ul>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="center">
          <ul>
            <li onClick={handleLoginRedirect}>
              <LoginIcon className="icon" />
              <span>Sign In</span>
            </li>
          </ul>
        </div>
      </UnauthenticatedTemplate>

    </div>
  );
};

export default Sidebar;
