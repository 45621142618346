import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";


import { EventType, PublicClientApplication } from '@azure/msal-browser';

import { msalConfig } from './authConfig';
import { Amplify } from "aws-amplify";
import { loginRequest } from './authConfig';

// awsExports.oauth = {
//   domain: "dhsso.dev.glidewell.ai",
//   scope: ["email", "openid", "aws.cognito.signin.user.admin"],
//   redirectSignIn: window.location.origin + "/login",
//   redirectSignOut: window.location.origin,
//   responseType: "code",
// };


Amplify.configure({
  API: {
    GraphQL: {
      endpoint: 'https://kpoimfj2ujesli3wgyyotb6vwm.appsync-api.us-east-1.amazonaws.com/graphql',
      region: 'us-east-1',
      defaultAuthMode: 'lambda',
    }
  }
});

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);

    sessionStorage.setItem('idToken', event.payload.idToken)
  }
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App instance={msalInstance} />
  </React.StrictMode>
);



