import Home from "./components/home/Home";
import List from "./components/users/List";
import Register from "./components/users/Register";
// import Checkin from "./components/users/Checkin";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./global.css"

/*
Imports required for API Calls
*/






function MainContent() {

  return (

    <div className="App">
      <BrowserRouter>
        <Home>
          <AuthenticatedTemplate>
            <Routes>
              <Route path="/" element={<div />} />
              <Route path="/register" element={<Register />} />
              {/* <Route
              path="/checkin"
              element={
                <Checkin {...{ name: checkinName, setName: setCheckinName }} />
              }
            /> */}
              <Route path="/list" element={<List />} />
            </Routes>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <h3 className="card-title">Please sign-in using the sidebar.</h3>
          </UnauthenticatedTemplate>
        </Home>
      </BrowserRouter>
    </div>

  );
}

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <MainContent />
    </MsalProvider>
  );
};

export default App;
