import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import "./Home.css";

const Home = ({ children }) => (
  <div className="home">
    <Sidebar />
    <div className="homeContainer">
      <Navbar />
      <hr />
      {children}
    </div>
  </div>
);

export default Home;
