import { Fragment, useState, useMemo, useEffect } from "react";
import "./List.css";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { generateClient } from 'aws-amplify/api';
import * as queries from "../../graphql/queries";

const columns = new Map([
  ["First Name", "firstname"],
  ["Last Name", "lastname"],
  ["Tech ID", "techID"],
  ["Department", "department_info"],
  ["HR Status", "hrstatus"],
  ["Last Check In", "lastCheckin"],
]);

function Row({ user }) {
  return (
    <tr className="tr">
      {Array.from(columns).map(([column, dataName]) => (
        <td key={column} className="td">
          {user[dataName] || "N/A"}
        </td>
      ))}
    </tr>
  );
}

const List = () => {
  const client = generateClient();
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      console.log("API Call");
      console.log(sessionStorage.getItem('idToken'))
      let results = await client.graphql({ query: queries.getUserDoorCheckInList, variables: undefined, authToken: sessionStorage.getItem('idToken'), authMode: 'lambda' })

      console.log("res ", results);
      setUsers(results.data.getUserDoorCheckInList);
    };
    fetchData();
  }, []);

  const [value, setValue] = useState("");
  const filteredUsers = users.filter((user) =>
    Array.from(columns.values()).some((property) =>
      String(user[property]).toLowerCase().includes(value.toLowerCase())
    )
  );

  const {
    items: sortedUsers,
    requestSort,
    sortConfig,
  } = useSortableData(filteredUsers, {
    key: "lastCheckin",
    direction: SortDirection.Descending,
    isDate: true,
  });
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return "";
    }
    return sortConfig.key === name
      ? sortConfig.direction === SortDirection.Ascending
        ? "ascending"
        : "descending"
      : "";
  };

  const usersPerPage = 5;
  const totalNumberUsers = sortedUsers.length;
  const maxPageNumber = Math.ceil(totalNumberUsers / usersPerPage);

  const [page, setPage] = useState(1);
  const lowerBound = (page - 1) * usersPerPage;
  const upperBound = page * usersPerPage;
  const pageUsers = sortedUsers.slice(lowerBound, upperBound);

  return (
    <>
      <h2>Registered Users</h2>
      <div className="pagination">
        <button
          className="changePageButton"
          disabled={page <= 1}
          onClick={() =>
            setPage((previousPage) => Math.max(previousPage - 1, 1))
          }
        >
          <ChevronLeft />
        </button>
        <label className="page">
          Page
          <input
            className="pageInput"
            value={page}
            onChange={(event) => {
              const newInput = event.target.value;
              if (newInput === "" || /^\d+$/.test(newInput)) {
                setPage(Number(event.target.value));
              }
            }}
            onFocus={(event) => event.target.select()}
            onBlur={() => {
              if (page > maxPageNumber) {
                setPage(maxPageNumber);
              } else if (page < 1) {
                setPage(1);
              }
            }}
          />
        </label>
        <button
          className="changePageButton"
          disabled={page >= maxPageNumber}
          onClick={() =>
            setPage((previousPage) => Math.min(previousPage + 1, maxPageNumber))
          }
        >
          <ChevronRight />
        </button>
        <input
          className="pageInput pageSearch"
          placeholder="Search"
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          onFocus={(event) => event.target.select()}
        />
      </div>
      {users.length ? (
        <div className="tableContainer">
          <table className="table">
            <thead>
              <tr className="tr">
                {Array.from(columns).map(([column, dataName]) => (
                  <th
                    key={column}
                    className={"th " + getClassNamesFor(dataName)}
                    onClick={() =>
                      requestSort(dataName, dataName === "lastCheckin")
                    }
                  >
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pageUsers.map((user) => (
                <Fragment key={user.techID}>
                  <Row {...{ user }} />
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        "Loading..."
      )}
    </>
  );
};

function compareDate(a, b) {
  var aDate = new Date(a).getTime();
  var bDate = new Date(b).getTime();

  if (aDate < bDate) {
    return -1;
  }
  if (aDate > bDate) {
    return 1;
  }
  return 0;
}

const SortDirection = {
  Ascending: -1,
  Descending: 1,
};
Object.freeze(SortDirection);

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        let result;
        if (sortConfig.isDate) {
          result = -1 * compareDate(a[sortConfig.key], b[sortConfig.key]);
        } else if (a[sortConfig.key] < b[sortConfig.key]) {
          result = -1;
        } else if (a[sortConfig.key] > b[sortConfig.key]) {
          result = 1;
        } else {
          result = 0;
        }
        return result * sortConfig.direction;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key, isDate) => {
    let direction = SortDirection.Descending;
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === SortDirection.Descending
    ) {
      direction = SortDirection.Ascending;
    }
    setSortConfig({ key, direction, isDate });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export default List;
