/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserDoorCheckInList = /* GraphQL */ `
  query GetUserDoorCheckInList {
    getUserDoorCheckInList {
      department_info
      firstname
      hrstatus
      lastCheckin
      lastname
      techID
      __typename
    }
  }
`;
export const registerDoorCheckInUser = /* GraphQL */ `
  query RegisterDoorCheckInUser(
    $imgData: String
    $payload: register_checkin_payload
  ) {
    registerDoorCheckInUser(imgData: $imgData, payload: $payload)
  }
`;
export const verifyUserRegistration = /* GraphQL */ `
  query VerifyUserRegistration($eid: Int) {
    verifyUserRegistration(eid: $eid) {
      department
      error
      first_name
      last_name
      __typename
    }
  }
`;
