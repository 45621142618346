import { useEffect, useRef, useState } from "react";
import { generateClient } from 'aws-amplify/api';
import "./Register.css";

/*
Imports required for API Calls
*/

import * as queries from "../../graphql/queries";

const State = {
  Forward: "forward",
  Left: "left",
  Right: "right",
  Review: "review"
};
Object.freeze(State);

const Camera = ({ label, active, onClick, setState, setImgData }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [taken, setTaken] = useState(false);

  // get video
  useEffect(() => {
    if (active) {
      if (taken) {
        onClick();
      } else {
        navigator.mediaDevices
          .getUserMedia({
            video: true
          })
          .then(stream => {
            const video = videoRef.current;
            video.srcObject = stream;
          });
      }
    }
  }, [videoRef, active, taken, onClick]);

  function handleClick() {
    onClick();
    setTaken(true);
    const video = videoRef.current;
    const canvas = canvasRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

    const data = canvas.toDataURL("image/jpeg");
    setImgData(data);
  }
  return (
    <div className={"camera" + (active ? " on" : "")}>
      <h4>Look {label}</h4>
      <video className="stream" hidden={!active} ref={videoRef} autoPlay />
      <canvas className="stream" hidden={active} ref={canvasRef} />
      <button
        className="snap"
        disabled={!taken && !active}
        onClick={
          taken && !active
            ? () => {
              setState(label);
              setTaken(false);
            }
            : handleClick
        }
      >
        {!taken ? "Take Picture" : "Retake"}
      </button>
    </div>
  );
};

const Register = () => {
  const client = generateClient();
  const [input, setInput] = useState("");
  const [isVerifyUser, setIsVerifyUser] = useState(false);
  const [isNotVerifyUser, setIsNotVerifyUser] = useState(false);
  const [user, setUser] = useState({});
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [state, setState] = useState(State.Forward);
  const [imgData, setImgData] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(null);
  const [unverifiedMessage, setUnverifiedMessage] = useState("")

  // registerable id 116453
  // already registered 116363
  // not active user
  async function VerifyUserApiCall(e) {
    e.preventDefault();

    console.log("API Call");
    let results = await client.graphql({
      query: queries.verifyUserRegistration, variables: {
        // eid: "116453"
        // eid: "116363",
        // eid: "0007"
        eid: input
      }, authToken: sessionStorage.getItem('idToken'), authMode: 'lambda'
    })

    results = results.data.verifyUserRegistration;
    setUser(results);
    console.log(results)
    if (results.department !== null || results.first_name !== null || results.last_name !== null) {
      setIsVerifyUser(true);
      setIsNotVerifyUser(false);
    } else {
      setIsNotVerifyUser(true);
      setIsVerifyUser(false);
      setUnverifiedMessage(results.error)
    }
  }

  async function handleRegister() {
    setIsSubmitted(true);
    // console.log("Making a second API call that is related to registering user");
    const payload = {
      firstname: user.first_name,
      lastname: user.last_name,
      department: user.department,
      eid: input
    };
    // console.log(payload);
    let results2 = await client.graphql({
      query: queries.registerDoorCheckInUser, variables: {
        payload,
        imgData
      }, authToken: sessionStorage.getItem('idToken'), authMode: 'lambda'
    })

    console.log(results2);
    setIsSuccessful(parseInt(results2.data.registerDoorCheckInUser, 10));
  }

  return (
    <>
      <h1>Register User</h1>
      {!(isVerifyUser || isNotVerifyUser) && (
        <form onSubmit={VerifyUserApiCall} className="form">
          <label className="idLabel">
            Employee ID
            <input
              type="text"
              placeholder="111111"
              value={input}
              onChange={event => {
                const newInput = event.target.value;
                if (newInput === "" || /^\d+$/.test(newInput)) {
                  setInput(event.target.value);
                }
              }}
              className="idInput"
            />
          </label>

          <button type="submit" className="button" disabled={!input}>
            Register
          </button>
        </form>
      )}
      {isVerifyUser && !isConfirmed && (
        <div>
          <label>First Name: {user.first_name}</label>
          <br />
          <label>Last Name: {user.last_name}</label>
          <br />
          <label>Department: {user.department}</label>
          <br />
          <button
            type="button"
            className="button"
            onClick={() => setIsConfirmed(true)}
          >
            Confirm
          </button>
        </div>
      )}
      {isNotVerifyUser && (
        <div>
          {/* <h1>User not verified</h1> */}
          {unverifiedMessage}
        </div>
      )}
      {isConfirmed &&
        (isSuccessful === null ? (
          <>
            <h3>You will need to take 3 pictures to register</h3>
            <div className="cameraContainer">
              <Camera
                label={State.Forward}
                active={state === State.Forward}
                onClick={() => setState(State.Left)}
                {...{ setState, setImgData }}
              />
              <Camera
                label={State.Left}
                active={state === State.Left}
                onClick={() => setState(State.Right)}
                setImgData={() => { }}
                {...{ setState }}
              />
              <Camera
                label={State.Right}
                active={state === State.Right}
                onClick={() => setState(State.Review)}
                setImgData={() => { }}
                {...{ setState }}
              />
            </div>
            {state === State.Review && (
              <button
                type="button"
                className="button"
                disabled={isSubmitted}
                onClick={handleRegister}
              >
                {isSubmitted ? "Registering!" : "Register"}
              </button>
            )}
          </>
        ) : (
          <h3>
            {isSuccessful === 1
              ? "Registration successful!"
              : "Registration unsuccessful"}
          </h3>
        ))}
    </>
  );
};

export default Register;
